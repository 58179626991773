<template>
   <div class="content-view order-details-screen">

      <h2 class="page-title">Ordine #{{ order.id }}</h2>

      <div class="riepilogo border radius-20 mb-3">
         <!-- prodotti -->

         <div class="table-line-row">
            <div class="name">
               <b>Prodotto</b>
            </div>
            <div class="price">
               <b>Totale</b>
            </div>
         </div>
         
         <div v-for="line in order.line_items" :key="line.id">
            <div class="table-line-row">
               <div class="name">
                  {{ line.name }} x {{ line.quantity }}
               </div>
               <div class="price">
                  {{ formatPrice(line.total) }}
               </div>
            </div>
         </div>

         <div class="table-line-row">
            <div class="name">
               <b>Metodo di pagamento</b><br>
               {{ order.payment_method_title }}
            </div>
            <div class="price">

            </div>
         </div>

         <div class="table-line-row">
            <div class="name">
               <b>IVA</b>
            </div>
            <div class="price">
               {{ formatPrice(order.total_tax) }}
            </div>
         </div>

         <div class="table-line-row">
            <div class="name">
               <b>Totale</b>
            </div>
            <div class="price">
               {{ formatPrice(order.total) }}
            </div>
         </div>

      </div>

      <!-- Fatturazione -->

      <h2 class="page-title">Fatturazione</h2>

      <div class="border py-2 px-3 radius-20 mb-3">
         {{ order.billing.company }} <br v-if="order.billing.company">
         {{ order.billing.first_name }} {{ order.billing.last_name }} <br>
         {{ order.billing.address_1 }} <br>
         {{ order.billing.address_2 }} <br v-if="order.billing.address_2">
         {{ order.billing.postcode }} - 
         {{ order.billing.city }} 
         ({{ order.billing.state }}), 
         {{ order.billing.country }} <br>
         {{ order.billing.email }} <br v-if="order.billing.phone">
         {{ order.billing.phone }} 
      </div>


   </div>
</template>

<script scoped>
import { Utils } from '@/inc/Utils';

export default {
   name: 'OrderDetails',
   mixins : [ Utils ],
   data(){
      return {
         order: {}
      }
   },
   created(){
      window.scrollTo(0,0);
      this.order = this.get_local_order_by_id(this.$route.params.id);
   }
}
</script>

<style scoped>

</style>